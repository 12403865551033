import { Col, Divider } from "antd"
import React, { memo, useContext, useEffect, useMemo, useState } from "react"
import { SettingContex } from "."
import { formatNumberJP } from "../../../../customUtil/global"
import ChangeQuanityDisiable from "../ChangeQuanityDisiable"
import ChangeQuanityStep from "./ChangeQuanityStep"

const SettingVariant = ({ title, price }) => {
  const { orderVariant } = useContext(SettingContex)
  const [count, setCount] = useState(0)
  const [toggleError, setToggleError] = useState(false)

  const inventoryQuantity = useMemo(() => price?.inventory_quantity, [price])

  useEffect(() => {
    if (price) {
      setCount(
        orderVariant.filter((item) => item.variant_id === price.id)[0]
          ?.amount ?? 0
      )
    }
  }, [price])
  return (
    <Col
      span={24}
      className="p-[3px] bg-white"
      style={{
        border: "1px solid rgba(188, 188, 188, 0.2)",
      }}
    >
      <div className="flex items-center">
        <div
          className={`w-20 flex justify-center h-[60px]
              items-center ${
                price ? (count ? "bg-pink-1" : "bg-black-6") : "bg-black-8"
              } text-black-0 font-medium text-[11px]`}
        >
          {title}
        </div>
        {price ? (
          <div
            className={`flex flex-col flex-1 items-center justify-center
                mx-[6px] px-[15px] ${
                  count ? "bg-pink-1" : "bg-black-6"
                } h-[60px]`}
          >
            <div className="text-black-5 text-xs font-bold">
              {formatNumberJP(price?.prices[0]?.min_price)}円 /バラ{" "}
              {formatNumberJP(price?.prices[0]?.amount_unit)}
              {price?.prices[0]?.unit} 入り
            </div>
            <Divider className={`!my-[2px] ${count ? "!bg-custom-1" : ""}`} />
            <div className="text-black-7 text-[11px]">
              <span className="text-black-5 font-bold">
                {formatNumberJP(price?.prices[0]?.m_price)}
              </span>
              円 /バラ {formatNumberJP(price?.prices[0]?.m_amount_unit)}個
              あたり
            </div>
            <div className="text-black-7 text-[11px]">
              {formatNumberJP(price?.prices[0]?.price_per_one)}{" "}
              {price?.prices[0]?.another_unit} / kg あたり
            </div>
          </div>
        ) : (
          <div className="h-[60px] bg-black-8 flex flex-1 mx-[6px] justify-center items-center text-xs font-bold">
            販売不可
          </div>
        )}
        {price ? (
          <ChangeQuanityStep
            height="h-[60px]"
            setToggleError={setToggleError}
            inventoryQuantity={inventoryQuantity}
            count={count}
            setCount={setCount}
            idVariant={price.id}
          />
        ) : (
          <ChangeQuanityDisiable />
        )}
      </div>
      {toggleError && (
        <div className="flex justify-center text-custom-1 text-xs">
          選択した数量がこの商品の最大数に達しました ({inventoryQuantity}{" "}
          商品まで)
        </div>
      )}
    </Col>
  )
}

export default memo(SettingVariant)
